<template>
	<div
		v-if="!webView"
		:class="{ 'bg-primary': !showBlackHeader }"
	>
		<div
			v-show="showModal"
			:class="[
				inputFocused ? 'search-modal' : showInput,
			]"
		>
			<div>
				<form
					autocomplete="off"
					@submit.prevent="submitSearch()"
				>
					<input
						ref="search"
						v-model="inputSearch"
						type="search"
						name="inputSearch"
						:title="`Buscar por ${inputSearch}`"
						:class="
							inputFocused
								? 'search-modal__input-petlove'
								: 'search__input-petlove'
						"
						:placeholder="placeholder"
						@keyup="onTyping($event)"
						@focus="handleFocus()"
						@blur="blurInput()"
					>
				</form>
				<div
					class="flex gap-3"
					:class="
						inputFocused
							? 'search-modal__icons-petlove'
							: 'search__icons-petlove'
					"
				>
					<CaIcon
						name="search"
						clickable
						@click="submitSearch()"
					/>
					<CaIcon
						v-if="inputFocused"
						name="close"
						clickable
					/>
				</div>
			</div>
			<div
				v-if="inputFocused && inputSearch === ''"
				class="search-modal__autocomplete"
			>
				<ul>
					<li
						class="search-modal__autocomplete-sugestion color-primary font-16"
					>
						Itens mais buscados
					</li>
					<li class="search-modal__autocomplete-sugestion">
						<a
							href="/busca?q=Ração"
							target="_self"
						>Ração</a>
					</li>
					<li class="search-modal__autocomplete-sugestion">
						<a
							href="/busca?q=Antipulgas"
							target="_self"
						>Antipulgas</a>
					</li>
					<li class="search-modal__autocomplete-sugestion">
						<a
							href="/busca?q=Tapete Higiênico"
							target="_self"
						>
							Tapete Higiênico
						</a>
					</li>
					<li class="search-modal__autocomplete-sugestion">
						<a
							href="/busca?q=Petiscos"
							target="_self"
						>Petiscos</a>
					</li>
					<li class="search-modal__autocomplete-sugestion">
						<a
							href="/busca?q=Brinquedos"
							target="_self"
						>Brinquedos</a>
					</li>
					<li class="search-modal__autocomplete-sugestion">
						<a
							href="/busca?q=Caminha"
							target="_self"
						>Caminha</a>
					</li>
				</ul>
			</div>
			<div
				v-if="inputFocused && inputSearch !== ''"
				class="search-modal__autocomplete"
			>
				<ul v-if="suggestions">
					<li
						v-for="(item, index) in suggestions"
						:key="`sugestion-${index}`"
						:class="{
							'search-modal__autocomplete-sugestion--focused':
								focusedItemIndex === index,
						}"
					>
						<a
							:href="`/busca?q=${item.term}`"
							class="search-modal__autocomplete-sugestion"
							target="_self"
							:title="`Buscar por ${item.term}`"
						>
							{{ item.term }}
						</a>
					</li>
				</ul>
				<ul v-if="products">
					<li
						v-for="(item, index) in products"
						:key="`product-${index}`"
					>
						<a
							:href="item.path"
							class="search-modal__autocomplete-products"
							target="_self"
							title="Ver produto"
						>
							<img
								:key="item.sku"
								loading="lazy"
								:src="item.image"
								:alt="item.name"
								class="search-modal__autocomplete-products--image"
							>
							<div class="search-modal__autocomplete-products--info">
								<div>{{ item.name }}</div>
								<div class="search-modal__autocomplete-products--price">
									{{ formatPrice(item.prices.original) }}
								</div>
							</div>
						</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { priceFormatter } from '@petlove/frontend-utilities-utils';
import { getCookie, setCookie } from '@petlove/lion/libs/cookies';
import { useUiStore } from '@petlove/lion/store/ui';
import { useSearchStore } from '@petlove/lion/store/search';

export default {
	name: 'InputSearch',
	props: {
		placeholder: {
			type: String,
			default: 'O que seu pet precisa?',
		},
		textValue: {
			type: String,
			default: '',
		},
		forceShowModal: {
			type: Boolean,
			default: false,
		},
		hideSearchInput: {
			type: Boolean,
			default: false,
		},
		showBlackHeader: {
			type: Boolean,
			default: false
		},
	},
	data() {
		return {
			inputSearch: this.textValue,
			inputFocused: false,
			debounce: null,
			focusedItemIndex: -1,
			autocompleteItems: [],
		};
	},
	computed: {
		...mapState(useUiStore, {
			webView: 'ui/getWebView',
		}),
		...mapState(useSearchStore, ['products', 'suggestions']),
		showInput() {
			return !this.hideSearchInput 
				? `search ${this.showBlackHeader ? 'search--black' : ''}`
				: '';
		},
		showModal() {
			if (this.forceShowModal) {
				this.$nextTick(() => {
					this.$refs.search.focus();
				});
				this.inputFocused = true;
				return true;
			}
			return !this.hideSearchInput;
		},
	},
	watch: {
		placeholder() {
			this.inputSearch = '';
			this.focusedItemIndex = -1;
		},
		autocompleteItems() {
			this.focusedItemIndex = -1;
		},
	},
	methods: {
		...mapActions(useSearchStore, ['fetchResults']),
		submitSearch() {
			sessionStorage.removeItem('search');
			const searchTerm = encodeURIComponent(this.inputSearch);
			window.location.href = `/busca?q=${searchTerm}`;
			setCookie('PL_search', searchTerm);
		},
		blurInput() {
			setTimeout(() => {
				this.inputFocused = false;
				this.autocompleteItems = [];
				this.inputSearch = '';
				this.$refs.search.blur();
				this.$emit('input-focused', true);
				sessionStorage.removeItem('search');
			}, 200);
		},
		onTyping(event) {
			const inputValue = this.inputSearch;
			if (!inputValue || inputValue?.length < 2) return;
			// if keypress is arrow up or down and has autocomplete items
			if (
				this.autocompleteItems?.length !== 0
				&& (event.keyCode === 38 || event.keyCode === 40)
			) {
				return this.browseAutocomplete(event.keyCode);
			}

			this.fetchResults(inputValue);
		},
		browseAutocomplete(keyCode) {
			const itemsQuantity = this.autocompleteItems.sugestoes?.length - 1;

			this.focusedItemIndex
        = keyCode === 40 ? this.focusedItemIndex + 1 : this.focusedItemIndex - 1;

			if (this.focusedItemIndex > itemsQuantity) this.focusedItemIndex = 0;
			else if (this.focusedItemIndex < 0) this.focusedItemIndex = itemsQuantity;

			this.inputSearch
        = this.autocompleteItems.sugestoes[this.focusedItemIndex].consulta;
		},
		handleFocus() {
			this.inputFocused = true;
			if (
				!getCookie('PL_ranking_sorting')
				&& typeof window?.DY?.API === 'function'
			) {
				window.DY.API('event', {
					name: 'click-at-menu-autocomplete',
				});
			}
		},
		formatPrice(price) {
			return priceFormatter(price);
		},
	},
};
</script>

<style lang="scss" scoped>
%basicList {
  display: flex;
  flex: 1;
  background-color: color(white);
  padding: 1rem 2rem;
  transition: all 0.1s ease-in;
  cursor: pointer;
 }

 %basicIcon {
  top: 1rem;
  position: absolute;
  right: 1.6rem;
  height: 30px;
  cursor: pointer;
  transition: all 0.2s ease-in;
 }

 %basicInput {
  letter-spacing: 0.03rem;
  padding: 1.6rem;
  appearance: none;
  -webkit-appearance: none;
  border-radius: 0.4rem;
  width: 100%;
  height: 4.8rem;
  font-size: 14px;
  transition: all 0.15s;
  border: none;
  outline: none;
  &::placeholder {
    font-size: 14px;
  }
 }

.search {
  width: 100%;
  text-align: left;
  position: relative;
  background-color: color(primary);
  padding: 0rem 1rem 0.5rem 1rem;

  &__input {
    &-petlove {
      @extend %basicInput;
      background-color: color(white);
      color: color(neutral);
    }
  }
  &__icons {
    &-petlove {
      @extend %basicIcon;
      svg {
        height: 30px;
        fill: color(neutral, medium);
        &:focus,
        &:active,
        &:hover {
          fill: color(white);
        }
      }
    }
  }

  &--black {
    background-color: $color-black;
  }
}

.search-modal {
  background-color: color(white);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 3;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  min-height: 90rem;

  &__input {
    &-petlove {
      @extend %basicInput;
      box-shadow: 0 2px 2px 0 rgba(0,0,0,.18), 0 2px 10px 0 rgba(0,0,0,.14);
    }
  }

  &__icons {
    &-petlove {
      @extend %basicIcon;
      svg {
        height: 30px;
        fill: color(neutral, medium);
        &:focus,
        &:active,
        &:hover {
          fill: color(white);
        }
      }
    }
  }

  &__autocomplete {
    text-align: left;
    padding: 1rem 0 1rem 0;
    &-sugestion {
      @extend %basicList;
      &:active {
        background-color: color(neutral, light);
      }
      &:hover {
        color: color(primary);
      }
    }

    &-products {
      @extend %basicList;
      border-top: 1px solid color(neutral, light);

      &--image {
        float: left;
        width: 75px;
        height: 75px;
        object-fit: contain;
      }
      &--info {
        padding-left: 2rem;
      }
      &--price {
        color: color(primary);
        margin-top: 0.3rem;
      }
      &:hover {
        color: color(primary);
      }
    }
  }
}
</style>
