<template>
	<svg
		fill="#fff"
		viewBox="0 0 1100 243"
		width="127"
		height="28"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
	>
		<clipPath id="a"><path d="m0 0h1100v242.394h-1100z" /></clipPath>
		<g clip-path="url(#a)">
			<path
				d="m1022.8.0000092c12.39.1835018 22.87 8.5557608 31.36 19.4396908 1.75 2.2479 5.62 3.1883 7.41.9749 16.55-20.41459054 38.43-15.7009 38.41 5.7458 1.26 43.5243-63.71 107.0386-79.55 56.0483-6.96-22.4216-23.259-37.6063-23.982-54.3279-.654-15.0701 11.272-28.098699 26.362-27.8807908z"
				fill="#ea534a"
			/>
			<g fill="#f3eee8">
				<path
					d="m8.32638 217.943c0-15.862 2.60342-19.509 10.13842-26 6.2391-5.196 7.019-10.403 7.019-19.245v-62.15c0-13.2692-.7799-17.4209-9.8747-17.6847-9.62237-.5161-15.60910312-4.1632-15.60910312-13.5218-.26378388-11.962 5.97527312-21.3206 15.34530312-29.1308 7.2827-5.9868 17.1574-10.1385 26.7798-10.1385 13.0056 0 21.0682 7.0189 21.3206 17.4212 0 6.239 1.3074 7.2827 3.8994 7.2827 3.6471 0 5.1954-4.4155 8.3263-8.5787 6.2391-9.8747 17.4212-18.2011 34.0626-18.2011 35.37 0 57.47 28.8671 57.47 73.8477 0 44.981-19.244 76.199-61.106 76.199-22.364-.264-22.8801-10.666-28.8668-10.402-5.9753.516-5.9753 5.195-5.7229 11.182.2637 3.9 1.5597 6.503 7.7988 10.139 8.8425 4.943 13.0056 10.138 13.0056 22.616s-5.7229 20.541-24.7038 20.805c-25.22 0-59.29399-3.12-59.29399-24.44zm102.71462-104.275c-3.383-17.4214-9.622-28.8673-23.6599-26.2639-12.7418 2.3397-14.8292 15.0819-12.7418 30.1629 2.5919 21.321 8.5786 27.824 22.8803 26 13.2584-1.296 16.9054-11.962 13.5214-29.911z"
				/>
				<path
					d="m345.098 143.051c0-14.565 2.855-26.528 2.855-42.389 0-7.7987-1.296-7.0188-10.402-7.2826-9.622-.5161-9.622-8.3264-9.622-20.0246 0-19.5085 3.647-17.685 29.383-31.2068 16.905-9.0948 26.264-15.8614 38.226-15.8614 5.459 0 10.138 2.6034 10.138 10.1385 0 10.4022 2.592 15.0356 14.302 15.0356 15.081 0 20.804 0 20.804 12.7419 0 7.2827-.78 11.4345-1.296 21.5844-.78 14.818-8.326 14.818-23.672 14.818-17.937 0-14.301-5.4136-14.817 11.492 0 5.459-.516 10.918-.516 15.861 0 8.063 4.679 12.226 11.962 12.226 6.766 0 14.301-1.824 20.024-1.824 4.943 0 7.799 2.604 7.535 13.522-.252 23.144-15.334 39.533-42.641 39.533-30.163 0-52.263-18.201-52.263-48.364z"
				/>
				<path
					d="m451.46 164.635c0-9.358.516-15.081 7.799-21.32 5.722-4.943 6.502-10.139 7.282-21.585.253-8.842.253-17.948.253-27.8231 0-10.666 0-28.6033-.253-38.4894-.264-8.0626-1.56-9.6224-8.842-10.1385-8.327-.2638-14.05-3.1195-14.05-13.258 0-21.0453 79.055-52.6993 79.055-11.962 0 7.019-.78 18.4649-2.087 38.7418-1.56 20.0246-3.9 49.9242-2.856 68.3892.516 7.535 1.56 10.666 6.503 15.081 5.459 4.679 8.315 9.622 8.315 20.805 0 19.244-6.503 26.527-40.829 26.527-30.427 0-40.302-2.603-40.302-24.968z"
				/>
				<path
					d="m539.357 116.271c0-45.2444 23.396-78.5385 74.892-78.0109 49.671.5161 72.551 32.5027 72.551 78.0109 0 45.509-23.671 76.452-74.123 76.452-50.451 0-73.332-30.427-73.332-76.452zm93.093-4.679c-7.019-22.3644-13.786-31.2068-27.308-27.5597-12.478 3.1195-15.861 16.3777-10.654 37.9737 5.195 20.024 14.038 27.043 27.043 23.924 14.038-3.12 16.642-15.082 10.919-34.327z"
				/>
				<path
					d="m727.366 165.151c-4.68-14.565-13.006-39.269-20.289-61.633-3.635-11.9623-5.459-14.818-10.402-18.7174-3.119-2.6034-4.415-6.7666-4.415-18.2011 0-20.2884 7.535-24.9677 43.421-24.9677 31.722 0 37.445 6.2391 37.445 22.3643 0 9.3586-1.032 13.7855-5.723 18.201-3.371 3.3834-3.899 6.2391-2.592 13.0057 2.592 11.9622 5.976 25.4842 8.843 36.1502 1.56 7.019 2.087 9.622 4.691 9.622 3.372 0 4.163-3.899 6.503-10.918 3.899-12.226 4.426-13.27 8.842-29.911 2.34-8.8426.264-13.5219-4.679-18.2012-2.592-3.1195-4.943-7.2827-4.943-17.1688 0-15.8614 3.899-22.6281 37.182-22.6281 23.671 0 25.747 6.7667 25.747 21.0683 0 11.1821-2.856 15.8614-5.975 19.5085-4.427 4.9431-5.723 7.2827-9.106 16.3775-9.875 25.7478-14.566 39.5328-24.704 67.8728-6.503 17.685-8.579 21.848-25.484 21.848h-21.057c-20.288 0-25.747-1.824-33.282-23.66z"
				/>
				<path
					d="m849.555 119.391c0-43.169 23.396-81.3947 74.364-81.3947 39.005 0 66.313 21.3206 66.313 65.5327 0 20.541-4.427 23.661-30.164 23.661h-53.307c-3.119 0-5.195 1.043-5.195 4.679 0 8.326 16.125 13.785 36.15 13.785 6.434 0 11.686-.47 16.102-1.215 5.838-.987 26.126-6.056 29.463 6.652.562 2.133.448 4.381-.068 6.514-5.391 22.318-28.707 35.118-59.271 35.118-44.465 0-74.364-29.384-74.364-73.332zm98.896-30.9432c-1.446-11.3198-11.527-21.3435-24.509-20.6898-16.573.6422-23.42 10.9986-22.342 26.8371.367 6.1469 3.246 7.1109 9.37 7.1109 34.235 0 38.57-4.5304 37.481-13.2582z"
				/>
				<path
					d="m178.639 121.467c0-43.1691 23.396-81.3947 74.364-81.3947 39.005 0 66.313 21.3206 66.313 65.5327 0 20.541-4.427 23.66-30.164 23.66h-53.307c-3.119 0-5.195 1.044-5.195 4.68 0 8.326 16.125 13.785 36.15 13.785 6.434 0 11.686-.47 16.102-1.215 5.838-.987 26.126-6.056 29.463 6.652.562 2.133.448 4.381-.068 6.514-5.391 22.318-28.707 35.117-59.271 35.117-44.465 0-74.364-29.383-74.364-73.331zm98.896-30.9433c-1.446-11.3197-11.527-21.3435-24.509-20.6898-16.573.6423-23.42 10.9986-22.342 26.8371.367 6.147 3.246 7.111 9.37 7.111 34.235 0 38.57-4.5305 37.481-13.2583z"
				/>
			</g>
		</g>
	</svg>
</template>
